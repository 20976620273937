import api from '@/base/utils/request'

export const getClientLoginSettings = (data) => {
  return api({ url: '/admin/admin/settings/getClientLoginSettings', method: 'post', data })
}

export const setClientLoginSettings = (data) => {
  return api({ url: '/admin/admin/settings/setClientLoginSettings', method: 'post', data })
}

