<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content medium-form"
      label-width="170px"
      :model="form"
      :rules="rules"
      ref="saveForm"
      style="width: 500px"
    >
      <template v-if="isTestVer">
        <el-form-item label="先登录后浏览：">
          <el-radio-group v-model="form.force_login">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
          <div class="form-tip" style="margin-top: 5px">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>开启后小程序需要登录后才可查看小程序内容</span>
          </div>
        </el-form-item>
      </template>

      <el-form-item label="未登录提醒弹窗：">
        <el-radio-group v-model="form.login_dialog_enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="未登录提醒弹窗内容：" prop="login_dialog_content">
        <el-input
          type="textarea"
          v-model="form.login_dialog_content"
          :rows="3"
          show-word-limit
          :maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="未登录提醒弹窗按钮：" prop="login_dialog_button">
        <el-input
          v-model="form.login_dialog_button"
          show-word-limit
          :maxlength="4"
        ></el-input>
        <div class="form-tip" style="margin-top: 5px">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>该按钮为进入登录按钮，无法跳过</span>
        </div>
      </el-form-item>
      <el-form-item label="入会申请提醒：">
        <el-radio-group v-model="form.join_dialog_enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="入会申请提醒弹窗内容：" prop="join_dialog_content">
        <el-input
          type="textarea"
          v-model="form.join_dialog_content"
          show-word-limit
          :rows="3"
          :maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="入会申请提醒弹窗按钮：" prop="join_dialog_button">
        <el-input
          v-model="form.join_dialog_button"
          :maxlength="4"
          show-word-limit
        ></el-input>
        <!-- <div class="form-tip" style="margin-top: 5px">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>该按钮为入会申请填写按钮，无法跳过</span>
        </div> -->
      </el-form-item>
      <el-form-item label="收藏小程序提醒：">
        <el-radio-group v-model="form.collect_mp_enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <p class="info" style="white-space: nowrap">
          <i class="el-icon-warning"></i
          >开启提醒后仅会在首次进入小程序时出现提醒
        </p>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
// 接口
import { getClientLoginSettings, setClientLoginSettings } from '../api/list.js'
import areaData from '@/modules/common/json/address.json'
export default {
  components: { FixedActionBar },
  data() {
    return {
      // 是否为测试版
      isTestVer:
        false && window.serverConfig.VUE_APP_COPYCONFIG_BASEURL === document.domain,
      form: {
        login_dialog_enable: 0,
        login_dialog_content: '',
        login_dialog_button: '',
        join_dialog_enable: 0,
        join_dialog_content: '',
        join_dialog_button: '',
      },
      loading: false,
      // 校验规则
      rules: {
        // 'person_native_place_setting.data':[{ required: true, message: '请选择地区', trigger: 'change' }],
        // 'company_region_setting.data': [{ required: true, message: '请选择地区', trigger: 'change' }],
        login_dialog_content: [
          {
            required: true,
            message: '请输入未登录提醒弹窗内容',
            trigger: 'blur',
          },
        ],
        login_dialog_button: [
          {
            required: true,
            message: '请输入未登录提醒弹窗按钮',
            trigger: 'blur',
          },
        ],
        join_dialog_content: [
          {
            required: true,
            message: '请输入入会申请提醒弹窗内容',
            trigger: 'blur',
          },
        ],
        join_dialog_button: [
          {
            required: true,
            message: '请输入入会申请提醒弹窗按钮',
            trigger: 'blur',
          },
        ],
      },
      areaData,
    }
  },
  methods: {
    // 保存配置
    saveConfig() {
      let postData = {
        config: {
          ...this.form,
        },
      }
      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true

          setClientLoginSettings(postData)
            .then((res) => {
              this.$message.success(res.msg)

              this.getDatail()

              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      this.loading = true
      getClientLoginSettings()
        .then((res) => {
          this.form = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getDatail()
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
